import React, {
  FormEvent,
  useEffect,
  useState,
  FC,
  memo,
  useRef,
  useCallback,
} from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { SVGIcon } from ".";
import { RootState } from "../../redux/store";
import { StoreService } from "../../redux/types";
import ImgUpload from "./imgUpload";

interface Props {
  title: string;
  show: boolean;
  loading: boolean;
  handleCancel: () => void;
  handleSave: (data: StoreService, reset: () => void) => void;
  name?: string;
  description?: string;
  image?: string;
  lawyerId?: number;
  isAdmin?: boolean;
}
const ServiceModal: FC<Props> = memo((props) => {
  const lawyers = useSelector((state: RootState) => state.entities.lawyers);
  // image upload logic
  const imgRootRef = useRef<HTMLElement | null>(null);
  const [image, setImage] = useState(props.image || "");
  const uploadCallback = useCallback((imageRootElm, path) => {
    imgRootRef.current = imageRootElm;
    setImage(path);
  }, []);
  // update image if necessary
  useEffect(() => {
    if (props.image && props.image !== image) {
      setImage(props.image || "");
    }
  }, [props.image]); // eslint-disable-line

  const defaultData = {
    name: props.name || "",
    description: props.description || "",
    lawyer_id: props.lawyerId || "",
  };
  const [inputs, setInputs] = useState(defaultData);
  // update inputs if necessary
  useEffect(() => {
    if (props.name && inputs.name !== props.name) {
      setInputs(defaultData);
    }
  }, [props.name]); // eslint-disable-line

  function handleInputChange(evt: FormEvent<any>) {
    setInputs({ ...inputs, [evt.currentTarget.name]: evt.currentTarget.value });
  }

  function reset() {
    setInputs(defaultData);
    setImage("");
  }

  function handleSubmit(evt: FormEvent) {
    evt.preventDefault();
    if (!image) {
      imgRootRef.current?.focus();
      return;
    }

    const data = { ...inputs, image };
    props.handleSave(data, reset);
  }

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    // this means that we are in the add category so comparison here doesn't make sense
    if (!props.name) return;

    if (
      props.lawyerId !== inputs.lawyer_id ||
      props.name !== inputs.name.trim() ||
      props.description !== inputs.description.trim() ||
      props.image !== image
    ) {
      if (disabled) setDisabled(false);
    } else {
      if (!disabled) setDisabled(true);
    }
  }, [inputs, image]); // eslint-disable-line

  return (
    <Modal show={props.show} onHide={props.handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{props.title}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <ImgUpload
            onChange={uploadCallback}
            image={image}
            style={{
              maxWidth: "160px",
              height: "160px",
              paddingTop: 0,
              marginBottom: "1rem",
              transition: "none", // transition conflicts with modal transition
            }}
            isAdmin={props.isAdmin}
          />
          {!props.isAdmin && (
            <select
              name="lawyer_id"
              aria-label="المحامي"
              className="custom-select mb-3"
              value={inputs.lawyer_id}
              required={true}
              onChange={handleInputChange}
            >
              <option value="" disabled={true}>
                لمن هذه الخدمة؟
              </option>
              {Object.values(lawyers).map((lawyer) => {
                return (
                  <option value={lawyer.id} key={lawyer.id}>
                    {lawyer.name}
                  </option>
                );
              })}
            </select>
          )}
          <input
            type="text"
            aria-label="الاسم"
            className="form-control--bordered mb-3"
            placeholder="الاسم"
            value={inputs.name}
            name="name"
            required={true}
            onChange={handleInputChange}
          />
          <textarea
            aria-label="الوصف"
            className="form-control--bordered"
            placeholder="الوصف"
            rows={3}
            value={inputs.description}
            name="description"
            required={true}
            onChange={handleInputChange}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="btn btn-success"
            disabled={disabled || props.loading}
          >
            حفظ
            {props.loading && (
              <SVGIcon id="#spinner" className="loading-icon mr-1" />
            )}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={props.handleCancel}
          >
            إلغاء
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
});

export default ServiceModal;
