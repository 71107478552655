import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ErrorBoundary, LdsRoller, PrivateRoute, Alert } from "../components";
const LawyerPage = lazy(() => import("../pages/lawyerPage"));
const Error404 = lazy(() => import("../pages/error404"));
const LoginPage = lazy(() => import("../pages/loginPage"));
const Dashboard = lazy(() => import("./dashboard"));
const Admin = lazy(() => import("./admin"));

function App() {
  return (
    <div className="app">
      <Suspense
        fallback={
          <div className="loader-container">
            <LdsRoller />
          </div>
        }
      >
        <Switch>
          <Route path="/" exact={true}>
            <ErrorBoundary>
              <LawyerPage domain={window.location.hostname} />
            </ErrorBoundary>
          </Route>
          <Route path="/404">
            <ErrorBoundary>
              <Error404 />
            </ErrorBoundary>
          </Route>
          <PrivateRoute path="/dashboard">
            <ErrorBoundary>
              <Dashboard />
            </ErrorBoundary>
          </PrivateRoute>
          <Route path="/login">
            <ErrorBoundary>
              <LoginPage />
            </ErrorBoundary>
          </Route>
          <PrivateRoute path="/admin" isAdmin={true}>
            <ErrorBoundary>
              <Admin />
            </ErrorBoundary>
          </PrivateRoute>
          <Route path="/admin-login">
            <ErrorBoundary>
              <LoginPage isAdmin={true} />
            </ErrorBoundary>
          </Route>
          <Route path="/:username">
            <ErrorBoundary>
              <LawyerPage />
            </ErrorBoundary>
          </Route>
        </Switch>
      </Suspense>

      <Alert />
    </div>
  );
}

export default App;
