import { CALL_API } from "../middlewares/apiService";
import {
  OnSuccess,
  OnFailure,
  StoreArea,
  AsyncTypes as Types,
  APIMiddlewareAction,
} from "../types";
import { area } from "../schema";

function fetchAreas(
  endpoint: string,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
): APIMiddlewareAction {
  return {
    name: "areas",
    // this is managed by apiService middleware
    [CALL_API]: {
      endpoint: endpoint,
      schema: { data: [area] },
      types: [
        Types.GET_ITEMS_REQUEST,
        Types.GET_ITEMS_SUCCESS,
        Types.GET_ITEMS_FAILURE,
      ],
      onSuccess,
      onFailure,
    },
  };
}

export function loadAreas(
  page = 1,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
) {
  return (dispatch: any, getState: any) => {
    const { pages, currPage } = getState().areas;
    // don't make pointless requests
    if (pages[page] !== undefined) {
      // keep track of the current page (useful for adding and deleting items)
      if (currPage !== page) {
        dispatch({
          type: Types.CHG_CURR_PAGE,
          name: "areas",
          page,
        });
      }
    } else {
      const endpoint = `/admin/areas?page=${page}`;
      dispatch(fetchAreas(endpoint, onSuccess, onFailure));
    }
  };
}

export function storeArea(
  data: StoreArea,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
): APIMiddlewareAction {
  return {
    name: "areas",
    [CALL_API]: {
      endpoint: "/admin/areas",
      schema: { data: area },
      types: [
        Types.POST_ITEM_REQUEST,
        Types.POST_ITEM_SUCCESS,
        Types.POST_ITEM_FAILURE,
      ],
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      },
      onSuccess,
      onFailure,
    },
  };
}

export function updateArea(
  areaId: number,
  data: StoreArea,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
): APIMiddlewareAction {
  return {
    name: "areas",
    [CALL_API]: {
      endpoint: `/admin/areas/${areaId}`,
      schema: { data: area },
      types: [
        Types.UPDATE_ITEM_REQUEST,
        Types.UPDATE_ITEM_SUCCESS,
        Types.UPDATE_ITEM_FAILURE,
      ],
      config: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      },
      onSuccess,
      onFailure,
    },
  };
}

export function deleteArea(
  areaId: number,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
): APIMiddlewareAction {
  return {
    name: "areas",
    [CALL_API]: {
      endpoint: `/admin/areas/${areaId}`,
      types: [
        Types.DELETE_ITEM_REQUEST,
        Types.DELETE_ITEM_SUCCESS,
        Types.DELETE_ITEM_FAILURE,
      ],
      config: {
        method: "DELETE",
      },
      onSuccess,
      onFailure,
    },
  };
}
