import React from "react";

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="container my-5">
          <h2 style={{ color: "#dc3545" }}>خطأ:&nbsp;</h2>
          <p>
            شئ ما خاطئ قد حدث، إذا كنت من مشرفي الموقع يمكنك رؤية رسالة تفصيلة
            عن الخطأ في الكونسول وإن لم تكن برجاء الاتصال بمشرفي الموقع وإخبارهم
            عما حدث.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
