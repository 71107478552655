import React from "react";
import SVGIcon from "./svgIcon";

interface SocialMedia {
  [key: string]: string | undefined;
}
interface Props {
  socialMedia: SocialMedia;
  logo?: string;
}
function Footer({ socialMedia, logo }: Props) {
  return (
    <footer className="footer">
      <nav className="footer__nav">
        <a href="/" className="logo" aria-label="logo">
          <img src={logo} alt="logo" />
        </a>
        {/* <a href="/">شروط الاستخدام</a>
        <a href="/">سياسة الخصوصية</a> */}
      </nav>
      <div className="footer__social">
        <span className="footer__copyrights">جميع الحقوق محفوظة © 2020</span>
        <div className="divider"></div>
        {Object.keys(socialMedia).map((platform, index) => {
          return (
            socialMedia[platform] && (
              <a
                href={socialMedia[platform]}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={platform}
              >
                <SVGIcon id={"#" + platform} title={platform} />
              </a>
            )
          );
        })}
      </div>
    </footer>
  );
}

export default Footer;
