import { merge } from "../../utils/helpers";
import { AsyncTypes as Types, EntitiesState } from "../types";
import asyncReducer from "./asyncReducer";

const initialState: EntitiesState = {
  roles: {},
  admins: {},
  categories: {},
  services: {},
  areas: {},
  cities: {},
  lawyers: {},
  messages: {},
};
// Updates an entity cache in response to any action with response.entities.
export function entities(state = initialState, action: any): EntitiesState {
  if (action.payload && action.payload.entities) {
    return merge(state, action.payload.entities);
  }
  // delete the item from its corresponding entity
  if (action.type === Types.DELETE_ITEM_SUCCESS) {
    const stateClone: any = { ...state };
    try {
      delete stateClone[action.name][action.payload.data.id];
    } catch {
      console.error({
        message: "Attempting to del item from its corresponding entity failed",
        action,
      });
    }
    return stateClone;
  }
  return state;
}

export const admins = asyncReducer("admins");
export const roles = asyncReducer("roles");
export const categories = asyncReducer("categories");
export const services = asyncReducer("services");
export const messages = asyncReducer("messages");
export const areas = asyncReducer("areas");
export const cities = asyncReducer("cities");
export const lawyers = asyncReducer("lawyers", true); // the second argument is just to use the filter logic
export { default as user } from "./userReducer";
export { default as alerts } from "./alertsReducer";
export { default as settings } from "./settingsReducer";
