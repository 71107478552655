import React, { ReactNode } from "react";

interface Props {
  message: string | ReactNode;
}
function InvalidFd(props: Props) {
  return (
    <span className="invalid-feedback" role="alert">
      {props.message}
    </span>
  );
}

export default InvalidFd;
