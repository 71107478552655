import React, { FormEvent, memo, useEffect, useRef } from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { SVGIcon } from ".";
import { Link, NavLink, useHistory } from "react-router-dom";
import { getQuery } from "../../utils/helpers";
import { logoutUser } from "../../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { loadSettings } from "../../redux/actions/settingsActions";

interface Props {}
const DashboardHeader = memo((props: Props) => {
  const dispatch = useDispatch();
  const logo = useSelector((state: RootState) => state.settings.website_logo);
  const isFetching = useSelector((state: RootState) => state.user.isFetching);

  useEffect(() => {
    // fetch settings on mount
    dispatch(loadSettings());
  }, []); // eslint-disable-line

  function handleLogout() {
    dispatch(
      logoutUser(false, () => {
        // remove token after logout
        localStorage.removeItem("token");
      })
    );
  }

  const history = useHistory();
  const currSearch = getQuery().get("search");

  const searchRef = useRef<HTMLInputElement>(null);
  function handleSubmitSearch(evt: FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    const currCategory = getQuery().get("category");
    const val = searchRef.current?.value as string;
    if (val.trim()) {
      history.push(
        currCategory
          ? `/dashboard?category=${currCategory}&search=${val}`
          : `/dashboard?search=${val}`
      );
    } else if (window.location.pathname === "/dashboard") {
      history.push(
        currCategory ? `/dashboard?category=${currCategory}` : `/dashboard`
      );
    }
  }
  return (
    <header className="dashboard-header">
      <Navbar expand="md">
        <Link to="/dashboard" className="navbar-brand">
          {logo && (
            <img src={logo} alt="logo" className="header__logo" />
          )}
          لوحة التحكم
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="dashboard-navbar-nav">
          <form
            className="dashboard-header__search-form"
            onSubmit={handleSubmitSearch}
          >
            <input
              className="form-control"
              type="search"
              placeholder="البحث"
              aria-label="Search"
              defaultValue={currSearch || undefined}
              ref={searchRef}
            />
          </form>
          <div className="dashboard-header__btn-group">
            <NavDropdown title="متقدم" id="dashboard-nav-dropdown">
              <NavLink className="dropdown-item" to="/dashboard/settings">
                إعدادات الموقع
              </NavLink>
              <NavLink className="dropdown-item" to="/dashboard/admins">
                مشرفو الموقع
              </NavLink>
              <NavLink className="dropdown-item" to="/dashboard/categories">
                التخصصات
              </NavLink>
              <NavLink className="dropdown-item" to="/dashboard/services">
                الخدمات
              </NavLink>
              <NavLink className="dropdown-item" to="/dashboard/areas">
                المناطق
              </NavLink>
              <NavLink className="dropdown-item" to="/dashboard/cities">
                المدن
              </NavLink>
            </NavDropdown>
            <div className="divider"></div>
            <button className="btn btn-link nav-item" onClick={handleLogout}>
              تسجيل الخروج
              {isFetching ? (
                <SVGIcon id="#spinner" className="loading-icon icon" />
              ) : (
                <SVGIcon id="#logout" />
              )}
            </button>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
});

export default DashboardHeader;
