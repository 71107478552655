import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { RootState } from "../../redux/store";

interface Props extends RouteProps {
  isAdmin?: boolean;
}
const PrivateRoute: FC<Props> = ({ isAdmin, children, ...rest }) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated
  );

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: isAdmin ? "/admin-login" : "/login",
          state: { from: window.location.pathname },
        }}
      />
    );
  }

  return <Route {...rest}>{children}</Route>;
};

export default PrivateRoute;
