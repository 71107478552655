import { Schema } from "normalizr";
import { CALL_API } from "./middlewares/apiService";

export type OnSuccess = (res: any) => void;
export type OnFailure = (err: any) => void;

/* Here I'm using numeric enum because it is faster in comparison and
in asyncReducer I check for name and action type not only action type */

// asyncReducer Higher Order Reducer Types
export enum AsyncTypes {
  GET_ITEMS_REQUEST,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAILURE,
  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,
  POST_ITEM_REQUEST,
  POST_ITEM_SUCCESS,
  POST_ITEM_FAILURE,
  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
  CHG_CURR_PAGE,
}

export interface AsyncState {
  isFetching: boolean;
  isStoring: boolean;
  // pages consists of arrays of ids
  pages: {
    [key: string]: number[];
  };
  total: number;
  currPage: number;
  // filter & search properties not needed
  queryTerm?: string;
  filterBy?: string;
}

// API Service middleware
export interface APIMiddlewareAction {
  [key: string]: any; // allow any other data to be passed away
  [CALL_API]: {
    endpoint: string;
    types: [string | number, string | number, string | number];
    schema?: Schema;
    config?: any;
    onSuccess?: OnSuccess;
    onFailure?: OnFailure;
  };
}

// admins
export interface Admin {
  id: number;
  name: string;
  email: string;
  status: number;
  roles: number[];
}

export interface StoreAdmin {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  roles: number[];
  status?: number; // needed in Put requests status
}

// alerts
export enum AlertsTypes {
  SHOW_ALERT = "SHOW_ALERT",
  HIDE_ALERT = "HIDE_ALERT",
}

interface ShowAlertAction {
  type: typeof AlertsTypes.SHOW_ALERT;
  message: string;
  variant?: string;
}

interface HideAlertAction {
  type: typeof AlertsTypes.HIDE_ALERT;
}

export type AlertsActionTypes = ShowAlertAction | HideAlertAction;

// areas
export interface Area {
  id: number;
  name: string;
}

export interface StoreArea {
  name: string;
}

// categories
export interface Category {
  id: number;
  name: string;
}

export interface StoreCategory {
  name: string;
}

// services
export interface Service {
  id: number;
  lawyer_id: number;
  name: string;
  description: string;
  created_at: string;
  image: string;
}

export interface StoreService {
  name: string;
  description: string;
  image?: string;
}

// cities
export interface City {
  id: number;
  name: string;
  area: number | null; // could be null if the user removed the area
}

export interface StoreCity {
  name: string;
  area_id: number;
}

// lawyers
export interface Lawyer {
  id: number;
  name: string;
  username: string;
  email: string;
  phone: string;
  image: string;
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  twitter?: string;
  snapchat?: string;
  services: number[];
  category: number | null; // could be null if the user removed the category!
  city: number | null; // could be null if the user removed the city!
  whatsapp: string | null;
  contact_us_phrase?: string;
  cover: string;
  logo: string;
  theme_color: string;
  map_url: string;
}

export interface StoreLawyer {
  category_id: number;
  city_id: number;
  name: string;
  username: string;
  email: string;
  password?: string;
  password_confirmation?: string;
  services: number[]; // array of ids
  image?: string; // not required in PUT request
  phone: string;
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  twitter?: string;
  snapchat?: string;
  whatsapp: string | null;
  contact_us_phrase?: string;
  cover?: string;
  logo?: string;
  map_url?: string;
  theme_color?: string;
}

// roles
export interface Role {
  id: number;
  name: string;
  guard_name: string;
}

// Entities
interface EntityType<ItemType> {
  [key: string]: ItemType;
}

export interface EntitiesState {
  admins: EntityType<Admin>;
  areas: EntityType<Area>;
  categories: EntityType<Category>;
  services: EntityType<Service>;
  cities: EntityType<City>;
  lawyers: EntityType<Lawyer>;
  roles: EntityType<Role>;
  messages: EntityType<Message>;
}

// user
export enum UserTypes {
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",
  LOGOUT_REQUEST = "LOGOUT_REQUEST",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_ERROR = "LOGOUT_ERROR",
}

// settings
export interface Settings {
  website_name: string;
  website_logo: string;
  about_us_description: string;
  about_us_image: string;
  address: string;
  phone: string;
  email: string;
}
export interface Message {
  id: number;
  email: string;
  subject: null | string;
  message: string;
  created_at: string;
}

export enum SettingsTypes {
  GET_SETTINGS_REQUEST = "GET_SETTINGS_REQUEST",
  GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS",
  GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR",
  UPDATE_SETTINGS_REQUEST = "UPDATE_SETTINGS_REQUEST",
  UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS",
  UPDATE_SETTINGS_ERROR = "UPDATE_SETTINGS_ERROR",
}
