export function uniqId() {
  return Math.random().toString(16).slice(2, 9);
}

export function getQuery(location: any = window.location) {
  return new URLSearchParams(location.search);
}

export function padWithZeros(no: number, leadingZeros = 2) {
  const stringNo = no.toString();
  if (stringNo.length >= leadingZeros) {
    return stringNo;
  }

  const zeros = Array(leadingZeros).fill(0).join("");
  return (zeros + stringNo).slice(-leadingZeros);
}

// Merge a `source` object to a `target` one level deep
export function merge(target: any, source: any) {
  // don't mutate the source object itself
  source = { ...source };
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) {
      source[key] = Object.assign({}, target[key], source[key]);
    }
  }

  // Join `target` and `source`
  return Object.assign({}, target, source);
}

// credit: https://stackoverflow.com/a/16436975
export function arraysEqual(a: any[], b: any[]) {
  if (a === b) return true;
  if (a.length !== b.length) return false;

  // cloning the source arrays then sorting them;
  a = a.slice();
  b = b.slice();
  a.sort();
  b.sort();

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export type Modify<T, R> = Omit<T, keyof R> & R;

export function adjustColor(color: string, amount: number) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
}
