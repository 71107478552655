import React, { memo } from "react";
import icons from "../../images/icons.svg";
import { uniqId } from "../../utils/helpers";

interface Props {
  id: string;
  className?: string;
  title?: string;
}
const SVGIcon = memo((props: Props) => {
  const id = uniqId();
  return (
    <svg className={props.className || "icon"} aria-labelledby={id} role="img">
      <title id={id}>{props.title || "icon"}</title>
      <use xlinkHref={icons + props.id} />
    </svg>
  );
});

export default SVGIcon;

// learn more about SVG sprite creation techniques
// https://24ways.org/2014/an-overview-of-svg-sprite-creation-techniques/
