import React, { FormEvent, useEffect, useState, FC, memo } from "react";
import Modal from "react-bootstrap/Modal";
import { SVGIcon } from ".";

type Data = {
  name: string;
};
interface Props {
  title: string;
  show: boolean;
  loading: boolean;
  handleCancel: () => void;
  handleSave: (data: Data, reset: () => void) => void;
  name?: string;
}
const CategoryModal: FC<Props> = memo((props) => {
  const defaultData = {
    name: props.name || "",
  };
  const [inputs, setInputs] = useState(defaultData);
  // update inputs if necessary
  useEffect(() => {
    if (props.name && inputs.name !== props.name) {
      setInputs(defaultData);
    }
  }, [props.name]); // eslint-disable-line

  function handleInputChange(evt: FormEvent<any>) {
    setInputs({ ...inputs, [evt.currentTarget.name]: evt.currentTarget.value });
  }

  function reset() {
    setInputs(defaultData);
  }

  function handleSubmit(evt: FormEvent) {
    evt.preventDefault();
    props.handleSave(inputs, reset);
  }

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    // this means that we are in the add category so comparison here doesn't make sense
    if (!props.name) return;

    if (String(props.name).trim() !== inputs.name.trim()) {
      if (disabled) setDisabled(false);
    } else {
      if (!disabled) setDisabled(true);
    }
  }, [inputs]); // eslint-disable-line

  return (
    <Modal show={props.show} onHide={props.handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{props.title}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <input
            type="text"
            aria-label="الاسم"
            className="form-control--bordered"
            placeholder="الاسم"
            value={inputs.name}
            name="name"
            required={true}
            onChange={handleInputChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="btn btn-success"
            disabled={disabled || props.loading}
          >
            حفظ
            {props.loading && (
              <SVGIcon id="#spinner" className="loading-icon mr-1" />
            )}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={props.handleCancel}
          >
            إلغاء
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
});

export default CategoryModal;
