import { AlertsActionTypes, AlertsTypes as Types } from "../types";

export function showAlert(
  message: string,
  variant?: string
): AlertsActionTypes {
  return {
    type: Types.SHOW_ALERT,
    message,
    variant,
  };
}

export function hideAlert(): AlertsActionTypes {
  return {
    type: Types.HIDE_ALERT,
  };
}
