import React, { FC, useEffect, useState, memo, CSSProperties } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { showAlert } from "../../redux/actions/alertsActions";
import { uploadImage } from "../../utils/api";
import SVGIcon from "./svgIcon";

interface Props {
  // pass data to parent through callback
  onChange: (rootRef: HTMLElement | null, image?: string) => void;
  image?: string;
  isAdmin?: boolean;
  style?: CSSProperties;
  isLoading?: boolean;
}
const ImgUpload: FC<Props> = memo((props) => {
  const dispatch = useDispatch();
  const [previewUrl, setPreviewUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [imgPath, setImgPath] = useState(props.image);
  // update image if necessary
  useEffect(() => {
    if (props.image && imgPath !== props.image) {
      setImgPath(props.image || "");
    }
  }, [props.image]); // eslint-disable-line

  const { getRootProps, getInputProps, isDragActive, rootRef } = useDropzone({
    accept: "image/jpeg,image/png",
    multiple: false,
    onDrop: (droppedFiles) => {
      const file = droppedFiles[0];
      // dropped files would be empty if user uploaded unsupported format
      if (file) {
        setPreviewUrl(URL.createObjectURL(file));
        setUploading(true);
        uploadImage(file, props.isAdmin)
          .then((res) => {
            setImgPath(res.path);
            setUploading(false);
          })
          .catch(() => {
            setUploading(false);
            dispatch(showAlert("حدث خطأ اثناء رفع الصورة"));
          });
      }
    },
  });

  useEffect(() => {
    // pass data to parent through callback
    props.onChange(rootRef.current, imgPath);
  }, [imgPath]); // eslint-disable-line

  // clean up as createObjectUrl creates memory leaks
  useEffect(() => {
    return function () {
      URL.revokeObjectURL(previewUrl);
    };
  }, [previewUrl]);

  return (
    <div
      {...getRootProps({
        className: "image-upload",
        style: {
          backgroundImage: previewUrl
            ? `url(${previewUrl})`
            : imgPath
            ? `url(${imgPath})`
            : undefined,
          ...props.style,
        },
        "data-drag": isDragActive,
        "data-empty": !previewUrl && !imgPath,
      })}
    >
      <input {...getInputProps()} />
      {(!!previewUrl || !!imgPath) && <span>تعديل</span>}
      {(uploading || props.isLoading) && (
        <SVGIcon id="#spinner" className="loading-icon" />
      )}
    </div>
  );
});

export default ImgUpload;
