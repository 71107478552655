import React, { FormEvent } from "react";
import SVGIcon from "./svgIcon";

interface Props {
  handleSubmit: (evt: FormEvent<HTMLFormElement>) => void;
  loading?: boolean;
}
function LoginForm(props: Props) {
  return (
    <form className="login-form" onSubmit={props.handleSubmit}>
      <div className="form-group">
        <label htmlFor="login-email" className="sr-only">
          email
        </label>
        <input
          type="email"
          name="email"
          id="login-email"
          className="form-control--bordered form-control-lg"
          placeholder="البريد الإلكتروني"
          required={true}
        />
      </div>
      <div className="form-group">
        <label htmlFor="password" className="sr-only">
          password
        </label>
        <input
          type="password"
          name="password"
          id="password"
          className="form-control--bordered form-control-lg"
          placeholder="كلمة المرور"
          required={true}
        />
      </div>
      <div className="form-group">
        <input type="checkbox" name="" id="rememberMe" />
        <label htmlFor="rememberMe" className="mb-0">
          &nbsp;تذكرني
        </label>
      </div>
      <button
        type="submit"
        className="btn btn-lg btn-primary"
        disabled={props.loading}
      >
        دخول
        {props.loading && (
          <SVGIcon id="#spinner" className="loading-icon mr-1" />
        )}
      </button>
    </form>
  );
}

LoginForm.defaultProps = {
  loading: false,
};

export default LoginForm;
