import React from "react";

interface Props {
  chart: string;
  label: string;
  number: string;
}
function StatisticsCard(props: Props) {
  return (
    <div className="statistics-card">
      <div>
        <p className="statistics-card__label">{props.label}</p>
        <div
          className="statistics-card__chart"
          style={{ backgroundImage: `url(${props.chart})` }}
        ></div>
      </div>
      <p className="statistics-card__number">{props.number}</p>
    </div>
  );
}

export default StatisticsCard;
