import React, { useRef, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";

interface Props {
  name: string;
  username: string;
  logo?: string;
}
function Header(props: Props) {
  const headerRef = useRef<HTMLElement>(null);
  useEffect(() => {
    // the padding top here is just like a placeholder for my fixed header
    // (I did it this way because header does not have fixed height)
    document.body.style.paddingTop = headerRef.current?.clientHeight + "px";
  }, [props.logo]); // the height of the header depends on logo

  const hash = window.location.hash;

  return (
    <header className="header" ref={headerRef}>
      <Navbar expand="md" className="container">
        {/* eslint-disable-next-line */}
        <a href="#" className="navbar-brand">
          {props.logo && (
            <img src={props.logo} alt="logo" className="header__logo" />
          )}
          <span>{props.name}</span>
        </a>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <ul className="navbar-nav mr-auto">
            <li className={hash === "" ? "nav-item active" : "nav-item"}>
              {/* eslint-disable-next-line */}
              <a className="nav-link" href="#">
                الرئيسية
              </a>
            </li>
            <li
              className={hash === "#services" ? "nav-item active" : "nav-item"}
            >
              <a href="#services" className="nav-link">
                الخدمات
              </a>
            </li>
            <li
              className={hash === "#contact" ? "nav-item active" : "nav-item"}
            >
              <a href="#contact" className="nav-link">
                اتصل بنا
              </a>
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header;
