import React, { Children, FC } from "react";
import Carousel from "react-bootstrap/Carousel";
import SVGIcon from "./svgIcon";

interface Props {
  cover: string;
}
const MainCarousel: FC<Props> = (props) => {
  return (
    <Carousel
      nextIcon={
        <span aria-hidden="true" className="carousel-control-svg-icon">
          <SVGIcon id="#right-arrow" />
        </span>
      }
      prevIcon={
        <span aria-hidden="true" className="carousel-control-svg-icon">
          <SVGIcon id="#left-arrow" />
        </span>
      }
      className="carousel--main"
      style={{
        backgroundImage: props.cover ? `url(${props.cover})` : undefined,
      }}
      pause="hover"
    >
      {Children.map(props.children, (child, index) => {
        return <Carousel.Item key={index}>{child}</Carousel.Item>
      })}
    </Carousel>
  );
};

export default MainCarousel;
