import thunkMiddleware from "redux-thunk";
import * as reducers from "./reducers";
import apiService from "./middlewares/apiService";
import { createStore, combineReducers, applyMiddleware } from "redux";
// don't import logger in production
let logger;
if (process.env.NODE_ENV === "development") {
  logger = require("redux-logger").default;
}

// enable logger only in development
const middlewares = [
  thunkMiddleware,
  apiService,
  process.env.NODE_ENV === "development" && logger,
].filter(Boolean) as any[];

const rootReducer = combineReducers(reducers);
const store = createStore(rootReducer, applyMiddleware(...middlewares));

export type RootState = ReturnType<typeof rootReducer>;

export default store;
