import React, { useEffect, useState, FC, memo, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { SVGIcon, MultiSelect } from ".";
import { validation } from "../../data";
import { RootState } from "../../redux/store";
import { StoreAdmin } from "../../redux/types";
import { useValidation } from "../../utils/hooks";
import InvalidFd from "./invalidFd";

interface Props {
  title: string;
  show: boolean;
  loading: boolean;
  handleCancel: () => void;
  handleSave: (data: StoreAdmin, reset: () => void) => void;
  name?: string;
  email?: string;
  roles?: number[];
}
const AdminModal: FC<Props> = memo((props: Props) => {
  const rolesEntity: any = useSelector(
    (state: RootState) => state.entities.roles
  );

  function onSubmit(data: StoreAdmin) {
    data.roles = selectedRoles; // select val is not native
    props.handleSave(data, reset);
  }

  const defaultData: Partial<StoreAdmin> = {
    name: props.name || "",
    email: props.email || "",
    password: "",
    password_confirmation: "",
  };
  const {
    inputs,
    setInputs,
    reset: resetData,
    handleChange,
    errors,
    handleSubmit,
  } = useValidation<StoreAdmin>(defaultData as StoreAdmin, onSubmit);
  // update inputs if necessary
  useEffect(() => {
    if (props.name && inputs.name !== props.name) {
      setInputs(defaultData);
    }
  }, [props.name, props.email, props.roles]); // eslint-disable-line

  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const onSelectChange = useCallback((val: number[]) => {
    setSelectedRoles(val);
  }, []);

  function reset() {
    resetData();
  }

  return (
    <Modal show={props.show} size="lg" onHide={props.handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{props.title}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit} noValidate>
        <Modal.Body>
          <div className="form-group">
            <input
              type="text"
              aria-label="الاسم"
              className="form-control--bordered"
              placeholder="الاسم"
              value={inputs.name}
              name="name"
              required={true}
              onChange={handleChange}
            />
            {errors.name && <InvalidFd message={validation.required} />}
          </div>
          <div className="form-group">
            <input
              type="email"
              aria-label="البريد الإلكتروني"
              className="form-control--bordered"
              placeholder="البريد الإلكترني"
              value={inputs.email}
              name="email"
              required={true}
              onChange={handleChange}
            />
            {errors.email && (
              <InvalidFd
                message={
                  errors.email.valueMissing
                    ? validation.required
                    : validation.invalidEmail
                }
              />
            )}
          </div>
          <div className="form-group">
            <input
              type="password"
              aria-label="كلمة السر"
              className="form-control--bordered"
              placeholder="كلمة السر"
              value={inputs.password}
              name="password"
              required={true}
              minLength={6}
              onChange={handleChange}
            />
            {errors.password && (
              <InvalidFd
                message={
                  errors.password.valueMissing
                    ? validation.required
                    : validation.shortPw
                }
              />
            )}
          </div>
          <div className="form-group">
            <input
              type="password"
              aria-label="تأكيد كلمة السر"
              className="form-control--bordered"
              placeholder="تأكيد كلمة السر"
              value={inputs.password_confirmation}
              name="password_confirmation"
              required={true}
              pattern={inputs.password}
              onChange={handleChange}
            />
            {errors["password_confirmation"] && (
              <InvalidFd
                message={
                  errors["password_confirmation"].valueMissing
                    ? validation.required
                    : validation.notSamePw
                }
              />
            )}
          </div>
          <div className="form-group mb-0">
            <MultiSelect
              options={rolesEntity}
              placeholder="الأدور"
              initialVal={props.roles}
              onChange={onSelectChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-success">
            حفظ
            {props.loading && (
              <SVGIcon id="#spinner" className="loading-icon mr-1" />
            )}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={props.handleCancel}
          >
            إلغاء
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
});

export default AdminModal;
