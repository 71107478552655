import React, { FC, memo } from "react";
import Modal from "react-bootstrap/Modal";
import { SVGIcon } from ".";

interface Props {
  body: string;
  show: boolean;
  isLoading?: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
}
const ConfirmModal: FC<Props> = memo((props) => {
  return (
    <Modal show={props.show} onHide={props.handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">تحذير</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-danger"
          onClick={props.handleConfirm}
          disabled={props.isLoading}
        >
          أجل
          {props.isLoading && (
            <SVGIcon id="#spinner" className="loading-icon mr-1" />
          )}
        </button>
        <button className="btn btn-outline-dark" onClick={props.handleCancel}>
          إلغاء
        </button>
      </Modal.Footer>
    </Modal>
  );
});

export default ConfirmModal;
