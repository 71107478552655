import React, {
  FormEvent,
  useEffect,
  useState,
  useRef,
  FC,
  memo,
  useMemo,
} from "react";

type Option = {
  id: number;
  name: string;
};
interface Props {
  options: { [key: string]: Option };
  // pass data to parent through callback
  onChange: (value: number[]) => void;
  placeholder?: string;
  initialVal?: number[];
  className?: string;
}
const MultiSelect: FC<Props> = memo((props) => {
  const elmRef = useRef<any>();
  const btnRef = useRef<any>();
  const [show, setShow] = useState(false);

  // value logic
  const [value, setValue] = useState<number[]>(props.initialVal || []);
  function handleChange(val: number) {
    const indxOfVal = value.indexOf(val);
    const newVal = value.slice();
    if (indxOfVal > -1) {
      // deselect the item if it is selected
      newVal.splice(indxOfVal, 1);
    } else {
      newVal.push(val);
    }
    setValue(newVal);
  }
  useEffect(() => {
    props.onChange(value);
  }, [value]); // eslint-disable-line

  // Esc key for clicking anywhere outside the component will hide it
  useEffect(() => {
    function handleKeyDown(evt: KeyboardEvent) {
      if (evt.key === "Esc" || evt.key === "Escape") {
        setShow(false);
      }
    }
    window.addEventListener("keydown", handleKeyDown);

    function handleClick(evt: any) {
      if (elmRef.current.contains(evt.target)) {
        return;
      }
      setShow(false);
    }
    window.addEventListener("click", handleClick);

    // cleanup
    return function () {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("click", handleClick);
    };
  }, []);

  function handleItemClick(evt: FormEvent<any>) {
    evt.preventDefault();
    const val = parseInt(evt.currentTarget.dataset.value);
    handleChange(val);
  }

  // select using space key
  function handleItemKeyDown(evt: React.KeyboardEvent<any>) {
    if (evt.key === " " || evt.key === "Enter") {
      const val = parseInt(evt.currentTarget.dataset.value);
      handleChange(val);
    }
  }

  // navigate using keyboard arrows
  // tab hides menu
  function handleElmKeyDown(evt: React.KeyboardEvent<HTMLDivElement>) {
    if (!show) return;
    evt.preventDefault();
    const items = elmRef.current.querySelectorAll("a");
    let currentIndex: number | null = null;
    const indexLen = items.length - 1;
    const first = items[0];
    const last = items[indexLen];
    // get the active element if any
    for (let i = 0; i <= indexLen; i++) {
      if (items[i] === document.activeElement) {
        currentIndex = i;
        break;
      }
    }

    switch (evt.key) {
      case "Down":
      case "ArrowDown":
        if (currentIndex === null || currentIndex === indexLen) {
          first.focus();
        } else {
          items[currentIndex + 1].focus();
        }
        break;
      case "Up":
      case "ArrowUp":
        if (currentIndex === null || currentIndex === 0) {
          last.focus();
        } else {
          items[currentIndex - 1].focus();
        }
        break;
      case "Tab":
        if (show) {
          evt.preventDefault();
          setShow(false);
          btnRef.current.focus();
        }
    }
  }

  // construct a string representation of value from options
  const strVal = useMemo(() => {
    let str = "";
    value.forEach((id) => {
      const currItem = props.options[id];
      if (currItem) str += str ? "، " + currItem.name : currItem.name;
    });
    return str;
  }, [props.options, value]);
  return (
    <div className="multi-select" ref={elmRef} onKeyDown={handleElmKeyDown}>
      <button
        className={`${
          props.className || "form-control--bordered"
        } multi-select-toggle ${show ? "focus" : ""}`}
        onClick={() => setShow(!show)}
        ref={btnRef}
        aria-expanded={show}
        type="button"
      >
        {strVal || <span className="muted">{props.placeholder}</span>}
      </button>
      {show && (
        <div className="multi-select-group" role="listbox" aria-expanded={show}>
          {Object.values(props.options).map((item) => {
            const selected = value.indexOf(item.id) > -1;
            return (
              <a
                key={item.id}
                href="test"
                className="multi-select-item"
                role="option"
                aria-selected={selected}
                data-value={item.id}
                onClick={handleItemClick}
                onKeyDown={handleItemKeyDown}
              >
                <span className="icon">{item.name}</span>
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
});

export default MultiSelect;
