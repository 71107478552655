import React, { FC, FormEvent, useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { SVGIcon } from ".";
import { loadAreas } from "../../redux/actions/areasActions";

type Data = {
  name: string;
  area_id: number | string;
};
interface Props {
  title: string;
  show: boolean;
  loading: boolean;
  handleCancel: () => void;
  handleSave: (data: Data, reset: () => void) => void;
  name?: string;
  areaId?: number | null;
}
const CityModal: FC<Props> = memo((props) => {
  const areasEntity = useSelector((state: any) => state.entities.areas);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAreas());
  }, []); // eslint-disable-line

  const defaultData = {
    name: props.name || "",
    area_id: props.areaId || "",
  };
  const [inputs, setInputs] = useState(defaultData);
  // update inputs if necessary
  useEffect(() => {
    if (props.name && inputs.name !== props.name) {
      setInputs(defaultData);
    }
  }, [props.name, props.areaId]); // eslint-disable-line

  function handleInputChange(evt: FormEvent<any>) {
    setInputs({ ...inputs, [evt.currentTarget.name]: evt.currentTarget.value });
  }

  function reset() {
    setInputs(defaultData);
  }

  function handleSubmit(evt: FormEvent) {
    evt.preventDefault();
    props.handleSave(inputs, reset);
  }

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    // this means that we are in the add city so comparison here doesn't make sense
    if (!props.name) return;

    if (
      props.name !== inputs.name.trim() ||
      props.areaId !== parseInt(inputs.area_id as string)
    ) {
      if (disabled) setDisabled(false);
    } else {
      if (!disabled) setDisabled(true);
    }
  }, [inputs]); // eslint-disable-line

  return (
    <Modal show={props.show} onHide={props.handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{props.title}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <input
            type="text"
            aria-label="الاسم"
            className="form-control--bordered mb-3"
            placeholder="الاسم"
            value={inputs.name}
            name="name"
            required={true}
            onChange={handleInputChange}
          />
          <select
            aria-label="المنطقة"
            className="custom-select"
            value={inputs.area_id}
            name="area_id"
            required={true}
            onChange={handleInputChange}
          >
            <option value="" disabled>
              المنطقة
            </option>
            {Object.keys(areasEntity).map((areaId) => {
              return (
                <option key={areaId} value={areaId}>
                  {areasEntity[areaId].name}
                </option>
              );
            })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="btn btn-success"
            disabled={disabled || props.loading}
          >
            حفظ
            {props.loading && (
              <SVGIcon id="#spinner" className="loading-icon mr-1" />
            )}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={props.handleCancel}
          >
            إلغاء
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
});

export default CityModal;
