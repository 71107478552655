import React, { useState } from "react";
import { validation } from "../../data";
import { useValidation } from "../../utils/hooks";
import InvalidFd from "./invalidFd";
import { contactLawyer } from "../../utils/api";
import { useDispatch } from "react-redux";
import SVGIcon from "./svgIcon";
import { showAlert } from "../../redux/actions/alertsActions";

interface Props {
  lawyerId: number;
}
function ContactForm(props: Props) {
  const dispatch = useDispatch();
  const [isSending, setIsSending] = useState(false);
  function onSubmit(FormData: any) {
    // adding lawyer id to data;
    FormData.lawyer_id = props.lawyerId;

    setIsSending(true);
    contactLawyer(FormData)
      .then((data) => {
        setIsSending(false);
        dispatch(showAlert(data.data.message, "success"));
      })
      .catch((error) => {
        dispatch(showAlert(error.message));
        setIsSending(false);
      });
  }

  const { inputs, handleChange, errors, handleSubmit } = useValidation(
    {
      name: "",
      email: "",
      message: "",
    },
    onSubmit
  );

  return (
    <form className="contact-form" onSubmit={handleSubmit} noValidate>
      <div className="form-group">
        <label htmlFor="contact-name" className="sr-only">
          name
        </label>
        <input
          type="text"
          placeholder="الاسم"
          id="contact-name"
          name="name"
          className="form-control"
          required
          value={inputs.name}
          onChange={handleChange}
        />
        {errors.name && <InvalidFd message={validation.required} />}
      </div>
      <div className="form-group">
        <label htmlFor="contact-email" className="sr-only">
          email
        </label>
        <input
          type="email"
          placeholder="البريد الإلكتروني"
          id="contact-email"
          name="email"
          className="form-control"
          required
          value={inputs.email}
          onChange={handleChange}
        />
        {errors.email && (
          <InvalidFd
            message={
              errors.email.valueMissing
                ? validation.required
                : validation.invalidEmail
            }
          />
        )}
      </div>
      <div className="form-group">
        <label htmlFor="contact-message" className="sr-only">
          email
        </label>
        <textarea
          rows={5}
          placeholder="رسالتك"
          id="contact-message"
          name="message"
          className="form-control"
          required
          value={inputs.message}
          onChange={handleChange}
        ></textarea>
        {errors.message && <InvalidFd message={validation.required} />}
      </div>
      <button type="submit" className="btn btn-dynamic" disabled={isSending}>
        أرسل
        {isSending && <SVGIcon id="#spinner" className="loading-icon mr-1" />}
      </button>
    </form>
  );
}

export default ContactForm;
