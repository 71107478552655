// here I set apis which doesn't require redux

import { API_ROOT } from "../redux/middlewares/apiService";

export async function uploadImage(file: File, isAdmin?: boolean) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("mime_type", "image");
  const endpoint = isAdmin
    ? API_ROOT + "/lawyer/upload-media"
    : API_ROOT + "/admin/upload";
  const res = await fetch(endpoint, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: formData,
  });
  const data = await res.json();
  if (!res.ok) throw data;

  return data;
}

export async function fetchLawyerByUsername(username: string) {
  const res = await fetch(API_ROOT + `/lawyers/${username}/show-by-username`, {
    headers: {
      Accept: "application/json",
    },
  });
  const data = await res.json();
  if (!res.ok) throw data;

  return data;
}

export async function fetchLawyerByDomain(domain: string) {
  const res = await fetch(API_ROOT + `/show-lawyer-by-domain`, {
    headers: {
      Accept: "application/json",
      Domain: domain,
    },
  });
  const data = await res.json();
  if (!res.ok) throw data;

  return data;
}

type ContactData = {
  name: string;
  email: string;
  message: string;
};
export async function contactLawyer(FormData: ContactData) {
  const res = await fetch(API_ROOT + `/contact-us/`, {
    method: "POST",
    body: JSON.stringify(FormData),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();
  if (!res.ok) throw data;

  return data;
}
