import { AlertsTypes as Types } from "../types";
export interface State {
  show: boolean;
  message: string;
  variant?: string;
}

const defaultState: State = {
  show: false,
  message: "",
};

function alertsReducer(state = defaultState, action: any) {
  // show errors to the user if action has an error object
  if (action.error) {
    let message: string;
    if (action.error.message === "Unauthenticated.") {
      message = "يجب عليك تسجيل الدخول";
    } else {
      const errObj = action.error.errors;
      // if errObj is an object show the first error inside of it
      message =
        typeof errObj === "object"
          ? errObj[Object.keys(errObj)[0]]
          : action.error.message;
    }
    return {
      ...state,
      show: true,
      message: message,
    };
  }

  switch (action.type) {
    case Types.SHOW_ALERT:
      return {
        ...state,
        show: true,
        message: action.message,
        variant: action.variant,
      };
    case Types.HIDE_ALERT:
      return { ...state, show: false };
    default:
      return state;
  }
}

export default alertsReducer;
