// NOTE: I didn't use useValidation here as I had to make custom validation for non native element (img)
import React, {
    useState,
    FormEvent,
    useEffect,
    memo,
    useRef,
    useCallback,
} from "react";
import {useDispatch, useSelector} from "react-redux";
import {validation} from "../../data";
import {StoreLawyer} from "../../redux/types";
import {loadCities} from "../../redux/actions/citiesActions";
import {loadCategories} from "../../redux/actions/categoriesActions";
import {RootState} from "../../redux/store";
import {ConfirmModal, ImgUpload, InvalidFd, SVGIcon} from ".";

// make all StoreLawyer properties optional
interface Props extends Partial<StoreLawyer> {
    handleSubmit: (data: StoreLawyer) => void;
    handleCancel: () => void;
    isLoading: boolean;
    isAdmin?: boolean; // customize the component in isAdmin page
}

const LawyerForm = memo((props: Props) => {
    console.log(props.map_url)
    const dispatch = useDispatch();
    const catEntity = useSelector(
        (state: RootState) => state.entities.categories
    );
    const cityEntity = useSelector((state: RootState) => state.entities.cities);
    useEffect(() => {
        const isAdmin = props.isAdmin;
        // change endpoint in isAdmin page
        dispatch(loadCities(1, isAdmin ? "/lawyer/cities" : undefined));
        dispatch(loadCategories(1, isAdmin ? "/lawyer/categories" : undefined));
    }, []); // eslint-disable-line

    type Errors = {
        [key: string]: ValidityState;
    };
    const [errors, setErrors] = useState<Errors>({});

    function handleSubmit(evt: FormEvent<HTMLFormElement>) {
        evt.preventDefault();
        const form = evt.currentTarget;
        // NOTE: image & MultiSelect elements are not native that's why I'm checking them manually
        if (
            form.checkValidity() &&
            image &&
            cover &&
            logo
        ) {
            const data = {...inputs};
            // add saudi country code to number if its not added by the user
            // by the pattern we applied to tel input, it will be just 9, 12 or 13 in length
            // if (data.phone?.length === 9) {
            //   data.phone = "+966" + data.phone;
            // } else if (data.phone?.length === 12) {
            //   data.phone = "+" + data.phone;
            // }
            data.image = image; // add image to data
            data.cover = cover; // add cover to data
            data.logo = logo; // add logo to data
            // add password confirmation to data if there is a password
            if (data.password) data.password_confirmation = data.password;

            props.handleSubmit(data as StoreLawyer);
            return;
        }

        // validation
        const invalidInputs: any = form.querySelectorAll(":invalid");
        const errors: Errors = {};
        invalidInputs.forEach((input: any) => {
            errors[input.name] = input.validity;
        });
        if (!image) errors.image = {valueMissing: true} as ValidityState;
        if (!cover) errors.cover = {valueMissing: true} as ValidityState;
        if (!logo) errors.logo = {valueMissing: true} as ValidityState;
        setErrors(errors);
        // focus first invalid input
        if (!image) imgRootRef.current?.focus();
        else if (!cover) coverRootRef.current?.focus();
        else if (!logo) logoRootRef.current?.focus();
        else {
            // (if doesn't have a name, that means its a fieldset)
            invalidInputs[0].name
                ? invalidInputs[0].focus()
                : invalidInputs[1].focus();
        }
    }

    // image file logic
    const imgRootRef = useRef<HTMLElement | null>(null);
    const [image, setImage] = useState(props.image);
    const handleImgChange = useCallback(
        (imageRootElm, path) => {
            imgRootRef.current = imageRootElm;
            // remove image error if any
            if (errors.image) delete errors.image;
            setImage(path);
        },
        [errors.image]
    );

    // cover file logic
    const coverRootRef = useRef<HTMLElement | null>(null);
    const [cover, setCover] = useState(props.cover);
    const handleCoverChange = useCallback(
        (coverRootElm, path) => {
            coverRootRef.current = coverRootElm;
            // remove image error if any
            if (errors.cover) delete errors.cover;
            setCover(path);
        },
        [errors.cover]
    );

    // logo file logic
    const logoRootRef = useRef<HTMLElement | null>(null);
    const [logo, setLogo] = useState(props.logo);
    const handleLogoChange = useCallback(
        (logoRootElm, path) => {
            logoRootRef.current = logoRootElm;
            // remove image error if any
            if (errors.logo) delete errors.logo;
            setLogo(path);
        },
        [errors.logo]
    );

    // inputs logic
    const [inputs, setInputs] = useState<Partial<StoreLawyer>>({
        username: props.username || "",
        name: props.name || "",
        category_id: props.category_id || ("" as any),
        city_id: props.city_id || ("" as any),
        phone: props.phone || "",
        email: props.email || "",
        password: "",
        facebook: props.facebook || "",
        instagram: props.instagram || "",
        twitter: props.twitter || "",
        linkedin: props.linkedin || "",
        snapchat: props.snapchat || "",
        whatsapp: props.whatsapp || "",
        contact_us_phrase: props.contact_us_phrase || "",
        cover: props.cover || "",
        logo: props.logo || "",
        map_url: props.map_url || "",
        theme_color: props.theme_color || ""
    });

    function handleInputChange(evt: any) {
        const curr = evt.currentTarget;
        setInputs({...inputs, [curr.name]: curr.value});

        // if form was validated, check if the input has been fixed
        // note that if all errors got fixed this will reset errors obj
        if (Object.keys(errors).length) {
            // remove it from errors obj if it was fixed
            if (curr.checkValidity()) {
                // notice that the line of code below does not trigger render as I mutated the state directly
                delete errors[curr.name];
            } else {
                // else update errors
                setErrors({...errors, [curr.name]: curr.validity});
            }
        }
    }

    // don't enable updating unless something has changed
    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        // this means that we are in the add lawyer page so comparison here doesn't make sense
        if (props.name === undefined) return;

        if (image !== props.image) {
            if (disabled) setDisabled(false);
            return;
        }

        if (cover !== props.cover) {
            if (disabled) setDisabled(false);
            return;
        }

        if (logo !== props.logo) {
            if (disabled) setDisabled(false);
            return;
        }

        for (const key of Object.keys(inputs)) {
            const inputVal = inputs[key as keyof StoreLawyer];
            const propVal = props[key as keyof Props];
            /* the second test is for nullable values
            as undefined (the prop) and empty string (the inputVal) cannot be equal,
            thus enabling the form when nothing has changed */
            if (
                String(inputVal).trim() !== String(propVal).trim() &&
                (inputVal || propVal)
            ) {
                if (disabled) setDisabled(false);
                return;
            }
        }
        // we reached here, this means nothing has changed
        if (!disabled) setDisabled(true);
    }, [inputs, image, cover, logo, props]); // eslint-disable-line

    // show confirm modal on cancel if any input have been edited
    const [showCancelConf, setShowCancelConf] = useState(false);

    function handleCancel() {
        // edit lawyer form logic
        if (props.name) {
            // if the form is disabled; this means nothing has been changed
            disabled ? props.handleCancel() : setShowCancelConf(true);
            return;
        }

        // add lawyer form logic
        if (image || cover || logo) {
            setShowCancelConf(true);
            return;
        }
        for (const key of Object.keys(inputs)) {
            if (String(inputs[key as keyof StoreLawyer]).trim()) {
                setShowCancelConf(true);
                return;
            }
        }

        props.handleCancel();
    }

    return (
        <form
            className="container-fluid dashboard-form"
            style={{border: "1px solid #eee"}}
            onSubmit={handleSubmit}
            noValidate
        >
            <div className="row">
                <div className="col-lg-2">
                    <fieldset className="row mb-3">
                        <legend className="sr-only">Pictures</legend>
                        <div className="col-lg-12 col-md-4">
                            <p className="mb-0">
                                <label>الصورة الشخصية</label>
                            </p>
                            <ImgUpload
                                image={image}
                                onChange={handleImgChange}
                                isAdmin={props.isAdmin}
                            />
                            {errors.image && <InvalidFd message={validation.required}/>}
                        </div>
                        <div className="col-lg-12 col-md-4">
                            <p className="mt-lg-3 mt-md-0 mt-3 mb-0">
                                <label>صورة الغلاف:</label>
                            </p>
                            <ImgUpload
                                image={cover}
                                onChange={handleCoverChange}
                                isAdmin={props.isAdmin}
                            />
                            {errors.cover && <InvalidFd message={validation.required}/>}
                        </div>
                        <div className="col-lg-12 col-md-4">
                            <p className="mt-lg-3 mt-md-0 mt-3 mb-0">
                                <label>صورة الشعار: </label>
                            </p>
                            <ImgUpload
                                image={logo}
                                onChange={handleLogoChange}
                                isAdmin={props.isAdmin}
                            />
                            {errors.logo && <InvalidFd message={validation.required}/>}
                        </div>
                    </fieldset>
                </div>
                <div className="col-lg-10">
                    <fieldset className="dashboard-form__info">
                        <legend className="sr-only">Information</legend>
                        <div className="form-group">
                            <label htmlFor="username">اسم المستخدم (الرابط)</label>
                            <input
                                type="text"
                                name="username"
                                id="username"
                                className="form-control--bordered"
                                placeholder="قم بانشاء اسم مستخدم"
                                value={inputs.username}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.username && <InvalidFd message={validation.required}/>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">الاسم</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                className="form-control--bordered"
                                placeholder="اكتب اسم المحامي"
                                value={inputs.name}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.name && <InvalidFd message={validation.required}/>}
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="category">التخصص</label>
                                    <select
                                        name="category_id"
                                        id="category"
                                        className="custom-select"
                                        value={inputs.category_id}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="" disabled>
                                            التخصص
                                        </option>
                                        {Object.keys(catEntity).map((catId) => {
                                            return (
                                                <option key={catId} value={catId}>
                                                    {catEntity[catId].name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {errors["category_id"] && (
                                        <InvalidFd message={validation.required}/>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="city">المدينة</label>
                                    <select
                                        name="city_id"
                                        id="city"
                                        className="custom-select"
                                        value={inputs.city_id}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="" disabled>
                                            المدينة
                                        </option>
                                        {Object.keys(cityEntity).map((cityId) => {
                                            return (
                                                <option key={cityId} value={cityId}>
                                                    {cityEntity[cityId].name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {errors["city_id"] && (
                                        <InvalidFd message={validation.required}/>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="tel">الجوال</label>
                                    <input
                                        type="tel"
                                        name="phone"
                                        id="tel"
                                        className="form-control--bordered"
                                        placeholder="اكتب الجوال"
                                        value={inputs.phone}
                                        onChange={handleInputChange}
                                        required
                                        pattern="\+?\d*"
                                        // pattern="^(\+?966)?\d{9}"
                                    />
                                    {errors.phone && (
                                        <InvalidFd
                                            message={
                                                errors.phone.valueMissing
                                                    ? validation.required
                                                    : validation.invalidPhone
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="email">البريد الإلكتروني</label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="form-control--bordered"
                                        placeholder="اكتب البريد الإلكتروني"
                                        value={inputs.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors.email && (
                                        <InvalidFd
                                            message={
                                                errors.email.valueMissing
                                                    ? validation.required
                                                    : validation.invalidEmail
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="contact-us">جملة اتصل بنا</label>
                                    <textarea
                                        rows={4}
                                        name="contact_us_phrase"
                                        id="contact-us"
                                        className="form-control--bordered"
                                        placeholder="الإفتراضية: يسعدنا تواصلك وتلقي استفساراتك، يرج..."
                                        value={inputs.contact_us_phrase}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="password">كلمة المرور</label>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        className="form-control--bordered"
                                        placeholder="أنشئ كلمة مرور جديدة"
                                        value={inputs.password}
                                        onChange={handleInputChange}
                                        minLength={6}
                                    />
                                    {errors.password && (
                                        <InvalidFd
                                            message={
                                                errors.password.valueMissing
                                                    ? validation.required
                                                    : validation.shortPw
                                            }
                                        />
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="theme_color">اللون الاساسي</label>
                                    <input
                                        type="color"
                                        name="theme_color"
                                        id="theme_color"
                                        className="form-control--bordered"
                                        placeholder="أنشئ كلمة مرور جديدة"
                                        value={inputs.theme_color}
                                        onChange={handleInputChange}
                                    />
                                    {errors.password && (
                                        <InvalidFd
                                            message={
                                                errors.password.valueMissing
                                                    ? validation.required
                                                    : validation.shortPw
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="social">
                        <legend>مواقع التواصل</legend>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <div className="form-group--icon">
                                    <SVGIcon id="#facebook"/>
                                    <input
                                        type="url"
                                        name="facebook"
                                        aria-label="Facebook"
                                        className="form-control--bordered"
                                        placeholder="https://www.facebook.com/"
                                        value={inputs.facebook}
                                        pattern="\S+" // spaces are not allowed in url
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {errors.facebook && (
                                    <InvalidFd message={validation.invalidUrl}/>
                                )}
                            </div>
                            <div className="col-md-6 form-group">
                                <div className="form-group--icon">
                                    <SVGIcon id="#instagram"/>
                                    <input
                                        type="url"
                                        name="instagram"
                                        aria-label="Instagram"
                                        className="form-control--bordered"
                                        placeholder="https://www.instegram.com/"
                                        value={inputs.instagram}
                                        pattern="\S+" // spaces are not allowed in url
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {errors.instagram && (
                                    <InvalidFd message={validation.invalidUrl}/>
                                )}
                            </div>
                            <div className="col-md-6 form-group">
                                <div className="form-group--icon">
                                    <SVGIcon id="#twitter"/>
                                    <input
                                        type="url"
                                        name="twitter"
                                        aria-label="Twitter"
                                        className="form-control--bordered"
                                        placeholder="https://www.twitter.com/"
                                        value={inputs.twitter}
                                        pattern="\S+" // spaces are not allowed in url
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {errors.twitter && (
                                    <InvalidFd message={validation.invalidUrl}/>
                                )}
                            </div>
                            <div className="col-md-6 form-group">
                                <div className="form-group--icon">
                                    <SVGIcon id="#linkedin"/>
                                    <input
                                        type="url"
                                        name="linkedin"
                                        aria-label="LinkedIn"
                                        className="form-control--bordered"
                                        placeholder="https://www.linkedin.com/"
                                        value={inputs.linkedin}
                                        pattern="\S+" // spaces are not allowed in url
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {errors.linkedin && (
                                    <InvalidFd message={validation.invalidUrl}/>
                                )}
                            </div>
                            <div className="col-md-6 form-group">
                                <div className="form-group--icon">
                                    <SVGIcon id="#snapchat"/>
                                    <input
                                        type="url"
                                        name="snapchat"
                                        aria-label="Snapchat"
                                        className="form-control--bordered"
                                        placeholder="https://www.snapchat.com/"
                                        value={inputs.snapchat}
                                        pattern="\S+" // spaces are not allowed in url
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {errors.snapchat && (
                                    <InvalidFd message={validation.invalidUrl}/>
                                )}
                            </div>
                            <div className="col-md-6 form-group">
                                <div className="form-group--icon">
                                    <SVGIcon id="#whatsapp"/>
                                    <input
                                        type="url"
                                        name="whatsapp"
                                        aria-label="WhatsApp"
                                        className="form-control--bordered"
                                        placeholder="https://api.whatsapp.com/"
                                        value={inputs.whatsapp as string}
                                        required
                                        pattern="\S+" // spaces are not allowed in url
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {errors.whatsapp && (
                                    <InvalidFd
                                        message={
                                            errors.whatsapp.valueMissing
                                                ? validation.required
                                                : validation.invalidUrl
                                        }
                                    />
                                )}
                            </div>
                            <div className="col-md-6 form-group">
                                <div className="form-group--icon">
                                    <SVGIcon id="#location"/>
                                    <input
                                        type="url"
                                        name="map_url"
                                        aria-label="Map URL"
                                        className="form-control--bordered"
                                        placeholder="https://g.page/"
                                        value={inputs.map_url as string}
                                        pattern="\S+" // spaces are not allowed in url
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {errors.map_url && (
                                    <InvalidFd
                                        message={
                                            errors.map_url.valueMissing
                                                ? validation.required
                                                : validation.invalidUrl
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </fieldset>
                    <div className="mt-4">
                        <button
                            type="submit"
                            className="btn btn-primary ml-4"
                            disabled={disabled || props.isLoading}
                        >
                            {/* if props.name is defined; we are editing otherwise we are adding */}
                            {props.name ? "حفظ" : "إضافة"}
                            {props.isLoading && (
                                <SVGIcon id="#spinner" className="loading-icon mr-1"/>
                            )}
                        </button>
                        {/* don't show cancel button in admin page */}
                        {!props.isAdmin && (
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={handleCancel}
                            >
                                إلغاء
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <ConfirmModal
                show={showCancelConf}
                body="جميع التغييرات سيتم تجاهلها، هل انت متأكد؟"
                handleCancel={useCallback(() => setShowCancelConf(false), [])}
                handleConfirm={props.handleCancel}
            />
        </form>
    );
});
export default LawyerForm;
