import React, { FC, useCallback, useEffect, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "../../redux/actions/alertsActions";
import { State } from "../../redux/reducers/alertsReducer";
import { RootState } from "../../redux/store";

interface Props {}
const CustomAlert: FC<Props> = (props) => {
  const alerts: State = useSelector((state: RootState) => state.alerts);
  const dispatch = useDispatch();
  const timeoutIdRef = useRef<number>();

  useEffect(() => {
    if (alerts.show) {
      clearTimeout(timeoutIdRef.current); // clear old timeout if any

      timeoutIdRef.current = window.setTimeout(() => {
        dispatch(hideAlert());
      }, 5000);
    }
  }, [alerts, dispatch]);

  const handleClose = useCallback(() => {
    dispatch(hideAlert());
    clearTimeout(timeoutIdRef.current);
  }, [dispatch]);

  return (
    <Alert
      variant={alerts.variant || "danger"}
      show={alerts.show}
      dismissible={true}
      onClose={handleClose}
    >
      {alerts.message}
    </Alert>
  );
};

export default CustomAlert;
