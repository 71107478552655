import React, { memo, MouseEvent } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SVGIcon } from ".";
import { getQuery } from "../../utils/helpers";

interface Props {
  count: number;
  pathname: string;
}

const Pagination = memo((props: Props) => {
  const history = useHistory();
  const location = useLocation();

  // default to the first page off course
  const page = parseInt(getQuery(location).get("page") || "1");

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            // handle disable
            disabled={page === 1}
            aria-label="Previous"
            onClick={(evt: MouseEvent) => {
              // change current page to the previous page
              history.push(`${props.pathname}page=${page - 1}`);
            }}
          >
            <SVGIcon id="#right-arrow" />
          </button>
        </li>
        {Array(Math.ceil(props.count)) // incase it was a float number
          .fill(0)
          .map((zero, index) => {
            const pageNumber = index + 1; // array starts at 0
            return (
              <li
                // suppress key warning
                key={index}
                className={
                  page === pageNumber ? "page-item active" : "page-item"
                }
              >
                <Link
                  className="page-link"
                  to={`${props.pathname}page=${pageNumber}`}
                >
                  {pageNumber}
                </Link>
              </li>
            );
          })}
        <li className="page-item">
          <button
            className="page-link"
            // handle disable
            disabled={page === Math.ceil(props.count)}
            aria-label="Next"
            onClick={(evt: MouseEvent) => {
              // change current page to the next page
              history.push(`${props.pathname}page=${page + 1}`);
            }}
          >
            <SVGIcon id="#left-arrow" />
          </button>
        </li>
      </ul>
    </nav>
  );
});

export default Pagination;
