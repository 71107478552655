import React, { MouseEvent, memo } from "react";
import SVGIcon from "./svgIcon";
import { Link } from "react-router-dom";

interface Props {
  id: number;
  username: string;
  image: string;
  title: string;
  subtitle: string;
  handleDelete: (id: number) => void;
}

const LawyerCard = memo((props: Props) => {
  function handleDelete(evt: MouseEvent) {
    evt.preventDefault();
    props.handleDelete(props.id);
  }

  return (
    <div className="lawyer-card">
      <div className="lawyer-card__body">
        <a
          href={`${window.location.origin}/${props.username}`}
          target="_blank"
          rel="noopener noreferrer"
          className="lawyer-card__img-link"
        >
          <img src={props.image} alt="" />
        </a>
        <h5 className="lawyer-card__title">{props.title}</h5>
        <h6 className="lawyer-card__subtitle">{props.subtitle}</h6>
      </div>
      <div className="lawyer-card__footer">
        <Link
          to={{
            pathname: `/dashboard/edit/${props.id}`,
            state: { from: window.location.pathname },
          }}
          className="lawyer-card__link"
        >
          <SVGIcon id="#pencil" />
          تعديل
        </Link>
        {/* eslint-disable-next-line */}
        <a
          href="#"
          role="button"
          className="lawyer-card__link"
          onClick={handleDelete}
        >
          <SVGIcon id="#trash" className="icon danger" />
          حذف
        </a>
      </div>
    </div>
  );
});

export default LawyerCard;
