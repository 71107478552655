// Custom Hooks
import { FormEvent, useState } from "react";

// types
type FormData = {
  [key: string]: any;
};
type Errors = {
  [key: string]: ValidityState;
};
// hook
export function useValidation<T extends FormData>(
  defaultVal: T,
  onSubmit: (data: T) => void
) {
  const [errors, setErrors] = useState<Errors>({});
  function handleSubmit(evt: FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    const form = evt.currentTarget;
    if (form.checkValidity()) {
      onSubmit(inputs);
      return;
    }

    // validation
    const invalidInputs: any = form.querySelectorAll(":invalid");
    const errors: Errors = {};
    invalidInputs.forEach((input: any) => {
      errors[input.name] = input.validity;
    });
    setErrors(errors);
    // focus first invalid input
    invalidInputs[0].focus();
  }

  const [inputs, setInputs] = useState<any>(defaultVal || {});
  function handleChange(evt: FormEvent<any>) {
    const curr = evt.currentTarget;
    setInputs({ ...inputs, [curr.name]: curr.value });

    // if form was validated, check if the input has been fixed
    // note that if all errors got fixed this will reset errors obj
    if (Object.keys(errors).length) {
      // remove it from errors obj if it was fixed
      if (curr.checkValidity()) {
        // notice that the line of code below does not trigger render as I mutated the state directly
        delete errors[curr.name];
      } else {
        // else update errors
        setErrors({ ...errors, [curr.name]: curr.validity });
      }
    }
  }

  function reset() {
    setInputs(defaultVal);
  }

  return { inputs, setInputs, handleChange, reset, errors, handleSubmit };
}
