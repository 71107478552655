import { schema } from "normalizr";

// roles
export const role = new schema.Entity("roles");

// admins
export const admin = new schema.Entity("admins", {
  roles: [role],
});

// areas
export const area = new schema.Entity("areas");

// categories
export const category = new schema.Entity("categories");

// categories
export const service = new schema.Entity("services");
export const message = new schema.Entity("messages");

// cities
export const city = new schema.Entity("cities", {
  area,
});

// lawyers
export const lawyer = new schema.Entity("lawyers", {
  city,
  category,
  services: [service],
  messages: [message],
});
