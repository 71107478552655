import { CALL_API } from "../middlewares/apiService";
import {
  OnSuccess,
  OnFailure,
  StoreCity,
  AsyncTypes as Types,
  APIMiddlewareAction,
} from "../types";
import { city } from "../schema";

function fetchCities(
  endpoint: string,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
): APIMiddlewareAction {
  return {
    name: "cities",
    // this is managed by apiService middleware
    [CALL_API]: {
      endpoint: endpoint,
      schema: { data: [city] },
      types: [
        Types.GET_ITEMS_REQUEST,
        Types.GET_ITEMS_SUCCESS,
        Types.GET_ITEMS_FAILURE,
      ],
      onSuccess,
      onFailure,
    },
  };
}

export function loadCities(
  page = 1,
  endpoint?: string,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
) {
  return (dispatch: any, getState: any) => {
    const { pages, currPage } = getState().cities;
    // don't make pointless requests
    if (pages[page] !== undefined) {
      // keep track of the current page (useful for adding and deleting items)
      if (currPage !== page) {
        dispatch({
          type: Types.CHG_CURR_PAGE,
          name: "cities",
          page,
        });
      }
    } else {
      endpoint = `${endpoint || "/admin/cities"}?page=${page}`;
      dispatch(fetchCities(endpoint, onSuccess, onFailure));
    }
  };
}

export function storeCity(
  data: StoreCity,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
): APIMiddlewareAction {
  return {
    name: "cities",
    [CALL_API]: {
      endpoint: "/admin/cities",
      schema: { data: city },
      types: [
        Types.POST_ITEM_REQUEST,
        Types.POST_ITEM_SUCCESS,
        Types.POST_ITEM_FAILURE,
      ],
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      },
      onSuccess,
      onFailure,
    },
  };
}

export function updateCity(
  cityId: number,
  data: StoreCity,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
): APIMiddlewareAction {
  return {
    name: "cities",
    [CALL_API]: {
      endpoint: `/admin/cities/${cityId}`,
      schema: { data: city },
      types: [
        Types.UPDATE_ITEM_REQUEST,
        Types.UPDATE_ITEM_SUCCESS,
        Types.UPDATE_ITEM_FAILURE,
      ],
      config: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      },
      onSuccess,
      onFailure,
    },
  };
}

export function deleteCity(
  cityId: number,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
): APIMiddlewareAction {
  return {
    name: "cities",
    [CALL_API]: {
      endpoint: `/admin/cities/${cityId}`,
      types: [
        Types.DELETE_ITEM_REQUEST,
        Types.DELETE_ITEM_SUCCESS,
        Types.DELETE_ITEM_FAILURE,
      ],
      config: {
        method: "DELETE",
      },
      onSuccess,
      onFailure,
    },
  };
}
