import { CALL_API } from "../middlewares/apiService";
import { admin } from "../schema";
import { APIMiddlewareAction, UserTypes as Types } from "../types";

export function loginUser(
  email: string,
  password: string,
  isAdmin?: boolean,
  onSuccess?: (res: any) => void,
  onFailure?: (error: any) => void
): APIMiddlewareAction {
  return {
    // this is managed by apiService middleware
    [CALL_API]: {
      endpoint: isAdmin ? "/lawyer-login" : "/admin-login",
      schema: { data: admin },
      types: [Types.LOGIN_REQUEST, Types.LOGIN_SUCCESS, Types.LOGIN_ERROR],
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      },
      onSuccess,
      onFailure,
    },
  };
}

export function logoutUser(
  isAdmin?: boolean,
  onSuccess?: (res: any) => void,
  onFailure?: (error: any) => void
): APIMiddlewareAction {
  // keep it simple for now
  return {
    [CALL_API]: {
      endpoint: isAdmin ? "/lawyer/logout" : "/admin/logout",
      types: [Types.LOGOUT_REQUEST, Types.LOGOUT_SUCCESS, Types.LOGOUT_ERROR],
      config: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
      onSuccess,
      onFailure,
    },
  };
}
