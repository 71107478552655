import { CALL_API } from "../middlewares/apiService";
import {
  OnSuccess,
  OnFailure,
  Settings,
  SettingsTypes as Types,
  APIMiddlewareAction,
} from "../types";

function fetchSettings(
  isGuest: boolean,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
): APIMiddlewareAction {
  return {
    // this is managed by apiService middleware
    [CALL_API]: {
      endpoint: isGuest ? "/settings" : "/admin/settings",
      types: [
        Types.GET_SETTINGS_REQUEST,
        Types.GET_SETTINGS_SUCCESS,
        Types.GET_SETTINGS_ERROR,
      ],
      onSuccess,
      onFailure,
    },
  };
}

export function loadSettings(
  isGuest = false,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
) {
  return (dispatch: any, getState: any) => {
    const { website_name } = getState().settings;
    // don't make pointless requests
    if (!website_name) {
      dispatch(fetchSettings(isGuest, onSuccess, onFailure));
    }
  };
}

export function updateSettings(
  data: Partial<Settings>,
  onSuccess?: OnSuccess,
  onFailure?: OnFailure
): APIMiddlewareAction {
  return {
    // this is managed by apiService middleware
    [CALL_API]: {
      endpoint: "/admin/settings",
      types: [
        Types.UPDATE_SETTINGS_REQUEST,
        Types.UPDATE_SETTINGS_SUCCESS,
        Types.UPDATE_SETTINGS_ERROR,
      ],
      config: {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      },
      onSuccess,
      onFailure,
    },
  };
}
