import { AsyncState, AsyncTypes as Types } from "../types";

const initialState: AsyncState = {
  isFetching: false,
  isStoring: false,
  pages: {},
  total: 0,
  currPage: 0,
};
function asyncReducer(
  state = initialState,
  action: any,
  useFilter: boolean
): AsyncState {
  switch (action.type) {
    case Types.GET_ITEMS_REQUEST:
    case Types.GET_ITEM_REQUEST:
      return { ...state, isFetching: true };

    case Types.GET_ITEMS_SUCCESS: {
      const result = action.payload.result;
      const currPage = result.meta.current_page;
      let pages = { ...state.pages, [currPage]: result.data };
      // reset old pages data if queryTerm or filterBy got changed ( when filter is needed )
      const { queryTerm, filterBy } = action;
      if (
        useFilter &&
        (queryTerm !== state.queryTerm || filterBy !== state.filterBy)
      ) {
        pages = { [currPage]: result.data };
      }
      return {
        ...state,
        isFetching: false,
        total: result.meta.total,
        pages,
        currPage,
        ...(useFilter && { queryTerm, filterBy }),
      };
    }

    case Types.GET_ITEMS_FAILURE:
    case Types.GET_ITEM_FAILURE:
    case Types.GET_ITEM_SUCCESS:
      return { ...state, isFetching: false };

    case Types.POST_ITEM_REQUEST:
    case Types.UPDATE_ITEM_REQUEST:
    case Types.DELETE_ITEM_REQUEST:
      return { ...state, isStoring: true };

    case Types.POST_ITEM_FAILURE:
    case Types.DELETE_ITEM_FAILURE:
    case Types.UPDATE_ITEM_FAILURE:
    case Types.UPDATE_ITEM_SUCCESS:
      return { ...state, isStoring: false };

    case Types.POST_ITEM_SUCCESS: {
      const pages = { ...state.pages }; // clone pages
      const currPage = state.currPage;
      // add the created item id to the begging of the current page
      if (currPage && pages[currPage]) {
        pages[currPage].unshift(action.payload.result.data);
      }
      return {
        ...state,
        isStoring: false,
        total: state.total + 1,
        pages,
      };
    }

    case Types.DELETE_ITEM_SUCCESS: {
      const pages = { ...state.pages }; // clone pages
      const currPage = state.currPage;
      // delete the item id from the current page if it exists
      if (currPage && pages[currPage]) {
        const indexOfDel = pages[currPage].indexOf(action.payload.data.id);
        if (indexOfDel > -1) pages[currPage].splice(indexOfDel, 1);
      }
      return {
        ...state,
        isStoring: false,
        total: state.total - 1,
        pages,
      };
    }

    case Types.CHG_CURR_PAGE:
      return { ...state, currPage: action.page };

    default:
      return state;
  }
}

// see: https://redux.js.org/recipes/structuring-reducers/reusing-reducer-logic#customizing-behavior-with-higher-order-reducers
// Named Wrapper Reducer
function namedWrapperReducer(reducerName: string, useFilter = false) {
  return (state: any, action: any): AsyncState => {
    const isInitializationCall = state === undefined;
    if (action.name !== reducerName && !isInitializationCall) return state;
    return asyncReducer(state, action, useFilter);
  };
}

export default namedWrapperReducer;
