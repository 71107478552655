import React from "react";

interface Props {
  title: string;
  text: string;
  image: string;
  [key: string]: string;
}

function Service({ title, text, image, ...rest }: Props) {
  return (
    <div className="service" {...rest}>
      <div className="service__icon">
        <img src={image} alt="Service" className="service__img" />
      </div>
      <h3 className="service__title">{title}</h3>
      <p className="service__text">{text}</p>
    </div>
  );
}

export default Service;
